import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import { Box, Container, Heading, Input, FormControl, FormLabel, Box as Section, Textarea } from "@chakra-ui/react";

// Components
import { SEO } from "../components/SEO";
import Img, { GatsbyImageProps } from "gatsby-image";

const ContactPage = ({ data }) => {
  const [disableSubmit, setDisableSubmit] = useState(true);

  return (
    <>
      <SEO title="Contact" />

      <Section mt="50px" pb={26}>
        <Container maxWidth="container.lg" p={6}>
          <Heading mt={4} textAlign="center" fontSize="3xl" textTransform="capitalize" color="brand.main">
            Shoot me a message
          </Heading>
          <Container
            display={["block", null, "grid"]}
            gridTemplateColumns="1fr 1fr"
            background="var(--color-white)"
            borderRadius="md"
            boxShadow="0 2px 6px 2px rgba(0, 0, 0, 0.1)"
            mx="auto"
            mt={38}
            px={0}
            maxWidth="100%"
            overflow="hidden"
            position="relative"
          >
            <BackgroundImage fluid={data.overpass.childImageSharp.fluid} />
            <Form action="https://getform.io/f/35e5c1c2-703e-4bc9-8caa-da265abb7d17" method="POST">
              <FormControl isRequired>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input type="text" name="name" id="name" />
              </FormControl>
              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input type="email" name="email" id="email" />
              </FormControl>
              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="message">Message</FormLabel>
                <Textarea name="message" id="message" rows={5} />
              </FormControl>
              <Box mt={4}>
                <ReCAPTCHA sitekey="6LdxD2caAAAAAGv6wJmRgaoKmi1O25tAZ3mjQ4IQ" onChange={() => setDisableSubmit(false)} />
              </Box>
              <SubmitButton type="submit" disabled={disableSubmit}>
                Holla at me
              </SubmitButton>
            </Form>
          </Container>
        </Container>
      </Section>
    </>
  );
};

const BackgroundImage = styled(Img)<GatsbyImageProps>`
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  @media ${({ theme }) => theme.mq.md} {
    position: absolute !important;
    width: 50%;
  }
`;
const Form = styled(motion.form)`
  grid-column-start: 2;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  color: var(--color-gray-700);
  margin: 0 auto;
  width: 100%;

  @media ${({ theme }) => theme.mq.sm} {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: block;
  letter-spacing: 0.08em;
  padding: 0.8rem;
  text-transform: uppercase;
  width: 100%;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    background-color: var(--color-primary-light);
  }
  &:disabled {
    background-color: var(--color-gray-300);
    cursor: default;
  }
`;

export const pageQuery = graphql`
  query {
    overpass: file(relativePath: { eq: "message-me.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default ContactPage;
